import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import Lottie from 'react-lottie';
import * as Sentry from "@sentry/react";

import Loading from "components/Loading.js";
import ClientContent from "components/ClientContent";

import LoggingService from "services/logging";

//import bgAnim from 'animations/bg_vertical.js';
import bgAnimGif from 'animations/bg_vertical.gif';

import "animate.css";
import styles from 'components/ClientStyles.module.scss';
import nosleep from 'nosleep.js';

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Question: "question",
    Answers: "answers",
    Results: "results",
    EndGame: "end_game",
};

const ViewTypes = {
    Default: "_DEFAULT",
    Tutorial: "_TUTORIAL",
    Question: "_QUESTION",
};

const gameId = "can_of_squirms";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);

        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            connected: false,
            blockNav: true,
            reconnectionToken: "",
            gameState: GameStates.Loading,
            players: [],
            logStreamId: "",

            showDefaultView: true,
            showTutorialView: false,
            showQuestionView: false,

            player: null,
            title: "",
            content: "",
            votedSkip: false,
            forceVotedSkip: false,

            question: "",
            hintText: "",
            isVSPlayer: false,
            vsPlayers: [],
            answer: null,
            bonusAnswer: null,
            desc: null,
            roundTimer: 0,
            isPaused: false,

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,

            //room: true,
            //player: {
            //    name: "SCOTT",
            //    avatar: 22,
            //},
            //connected: true,
            //isVSPlayer: true,
            //hintText: "WHAT WILL THEY SAY?",
            //question: {
            //    question: "Stranded on an island of monkeys, who would be most likely to rise up and become their leader?",
            //},
            //vsPlayers: [
            //    {
            //        id: "test-1",
            //        name: "BOB",
            //        avatar: 6,
            //    },
            //    {
            //        id: "test-2",
            //        name: "JUSTIN",
            //        avatar: 14,
            //    },
            //],
            //title: "YOU WERE RIGHT!",
            //content: "You're a genius!",
        };
        this.locationCheckInterval = null;


        //window.onbeforeunload = () => {
        //    if (!this.state.redirect) {
        //        if (this.state.room) {
        //            this.state.room.leave(false);
        //        }
        //    }
        //}
    }


    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            const node = document.querySelector(element);

            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    doNewQuestion(question) {
        if (supportsVibrate) navigator.vibrate(500);
        if (this.state.isVSPlayer) {
            this.setState({ question: question, hintText: "", });
            this.setView(ViewTypes.Question);
        } else {
            this.setState({ question: question, hintText: "WHAT WILL THEY SAY?", });
            this.setView(ViewTypes.Question);
        }
    }


    choosePlayer(id) {
        this.setState({ answer: id });
    }

    submitVSAnswer(force) {
        if (this.state.answer != null) {
            this.state.room.send("player_answer", { answer: this.state.answer, notes: this.state.desc });
            this.setState({ answer: null, desc: null, });
            this.updateContent("ANSWER SUBMITTED!", "");
            this.setView(ViewTypes.Default);
        } else if (force) {
            this.state.room.send("player_answer", { answer: this.GetRandomElement(this.state.vsPlayers).id, notes: this.state.desc });
            this.setState({ answer: null, desc: null, });
            this.updateContent("ANSWER SUBMITTED!", "");
            this.setView(ViewTypes.Default);
        }
    }
    submitGuessAnswer(force) {
        if (this.state.answer != null /*&& this.state.bonusAnswer != null*/) {
            this.state.room.send("guess_answer", { answer: this.state.answer, bonusAnswer: this.state.bonusAnswer, });
            this.setState({ answer: null, bonusAnswer: null, });
            this.updateContent("ANSWER SUBMITTED!", "");
            this.setView(ViewTypes.Default);
        } else if (force) {
            this.state.room.send("guess_answer", { answer: this.state.answer ? this.state.answer : this.GetRandomElement(this.state.vsPlayers).id, bonusAnswer: this.state.bonusAnswer ? this.state.bonusAnswer : this.GetRandomElement(this.state.vsPlayers).id, });
            this.setState({ answer: null, bonusAnswer: null, });
            this.updateContent("ANSWER SUBMITTED!", "");
            this.setView(ViewTypes.Default);
        }
    }
    chooseOptionAndSubmit(answer) {
        this.updateContent("ANSWER SUBMITTED!", "");
        this.setState({ answer, });
        this.state.room.send("guess_answer", { answer: answer, bonusAnswer: this.state.bonusAnswer, });
    }

    GetRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    doCatchup(gameState = null) {
        const player = { ...this.state.player };
        if (!player) return;

        switch (gameState || this.state.gameState) {
            case GameStates.Tutorial:
                if (!this.state.votedSkip && this.state.forceVotedSkip == false) {
                    this.setView(ViewTypes.Tutorial);
                } else {
                    this.setView(ViewTypes.Default);
                }
                break;
            case GameStates.Question:
                if (!this.state.showQuestionView) {
                    if (this.state.isVSPlayer) {
                        if (!player.cosData.ready) this.state.room.send("request_question", {});
                    } else {
                        this.state.room.send("request_question", {});
                    }
                }
                break;
            default:
                this.setView(ViewTypes.Default);
                break;
        }
    }

    setView(viewType) {
        switch (viewType) {
            case ViewTypes.Tutorial:
                this.setState({ showDefaultView: false, showTutorialView: true, showQuestionView: false, });
                break;
            case ViewTypes.Question:
                this.setState({ showDefaultView: false, showTutorialView: false, showQuestionView: true, });
                break;
            default:
                this.setState({ showDefaultView: true, showTutorialView: false, showQuestionView: false, });
                break;
        }
    }



    updateDesc = (event) => {
        this.setState({ desc: event.target.value, });
    }

    doForceAnswer() {
        if (this.state.showQuestionView) {
            if (this.state.isVSPlayer) {
                this.submitVSAnswer(true);
            } else {
                this.submitGuessAnswer(true);
            }
        }
    }

    updateContent(title, content, scores = false) {
        if (scores) {
            this.setState({ player: JSON.parse(JSON.stringify(this.state.roomState.players[this.state.myId])), });
        }
        this.setState({ title, content });
    }

    isVersusPlayer(vsPlayers, id) {
        return vsPlayers.includes(id);
    }


    getArrayOfPlayers(statePlayers) {
        let arrPlayers = [];
        statePlayers.forEach((value, key) => {
            arrPlayers.push(value);
        });

        return arrPlayers;
    }

    getVsPlayersByIds(statePlayers, ids) {
        let vsPlayers = [];
        statePlayers.forEach((value, key) => {
            if (ids.includes(value.id)) vsPlayers.push(value);
        });

        return vsPlayers;
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ votedSkip: true, forceVotedSkip: true, });
    }

    goToLobby() {
        this.setState({ blockNav: false, });
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }


    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    updatePlayerProperty(property, value) {
        let player = { ...this.state.player };
        player.cosData[property] = value;
        this.setState({ player, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }


    doReconnect = () => {
        const token = this.getQueryStringValue("token");

        if (this.state.connected == false) {
            this.client.reconnect(token).then(room => {
                console.log(room.sessionId, "joined", room.name);

                this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });

                this.updateToken(room.reconnectionToken);
                room.send("update_player_token", { reconnectionToken: room.reconnectionToken });

                room.onStateChange.once((state) => {
                    console.log("this is the first room state!", state);
                    const player = state.players[this.state.myId];
                    if (!player) window.location = this.getRedirectURL();
                    Sentry.setUser({ id: player.uniqueId });
                    LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Can of Squirms, Reconnection Token: ${room.reconnectionToken}`);

                    this.setState({
                        roomState: state,
                        logStreamId: state.uniqueId,
                    });
                    this.startLocationChecks();

                    room.state.cosData.vsPlayers.onAdd((value, index) => {
                        console.log("vsPlayers.onAdd", value, index);
                        let vsPlayers = [...this.state.vsPlayers];
                        let statePlayers = [...this.state.players]
                        vsPlayers.push(statePlayers.find(p => p.id === value));
                        let isVSPlayer = false;
                        console.log("Vs Players", vsPlayers);
                        if (vsPlayers.length > 0) {
                            isVSPlayer = vsPlayers.find(v => v.id == this.state.myId);
                        }
                        this.setState({ vsPlayers, isVSPlayer, });
                    });
                    room.state.cosData.vsPlayers.onRemove((value, index) => {
                        console.log("vsPlayers.onRemove", value, index);

                        this.setState({ vsPlayers: [], isVSPlayer: false, });
                    });
                });
                room.onStateChange((state) => {
                    console.log(room.name, "has new state:", state);
                    this.setState({
                        roomState: state,
                    });
                });


                room.state.players.onAdd((player, key) => {
                    this.checkAndAddPlayer(player);
                    player.listen("connected", (currentValue, previousValue) => {
                        let statePlayers = [...this.state.players];
                        let index = statePlayers.findIndex(elem => elem.id === player.id);
                        statePlayers[index].connected = currentValue;
                        this.setState({ players: statePlayers });
                    });

                    if (player.id === room.sessionId) {
                        this.setState({ player: player, });

                        player.listen("votedSkip", (currentValue, previousValue) => {
                            this.setState({ votedSkip: currentValue, });
                        });
                        player.cosData.onChange(() => {
                            this.doCatchup();
                        });

                        player.cosData.listen("answer", (currentValue, previousValue) => {
                            this.updatePlayerProperty("answer", currentValue);
                        });
                        player.cosData.listen("bonusAnswer", (currentValue, previousValue) => {
                            this.updatePlayerProperty("bonusAnswer", currentValue);
                        });
                        player.cosData.listen("ready", (currentValue, previousValue) => {
                            this.updatePlayerProperty("ready", currentValue);
                        });
                        player.cosData.listen("notes", (currentValue, previousValue) => {
                            this.updatePlayerProperty("notes", currentValue);
                        });
                        player.cosData.listen("score", (currentValue, previousValue) => {
                            this.updatePlayerProperty("score", currentValue);
                        });
                        player.cosData.listen("turnsTaken", (currentValue, previousValue) => {
                            this.updatePlayerProperty("turnsTaken", currentValue);
                        });
                    }
                });

                room.state.host.listen("connected", (value) => {
                    this.setState({ hostConnected: value });
                });

                room.state.listen("isPaused", (currentValue, previousValue) => {
                    this.setState({ isPaused: currentValue, });
                });
                room.state.cosData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                    this.doCatchup(currentValue);
                });

                room.onMessage("end_tutorial", (message) => {
                    console.log("end_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Default);
                });
                room.onMessage("update_timer", (message) => {
                    console.log("update_timer", "received on", room.name, message);
                    this.setState({ roundTimer: message.count, });
                });
                room.onMessage("start_playing", (message) => {
                    console.log("start_playing", "received on", room.name, message);

                });
                room.onMessage("start_question", (message) => {
                    console.log("start_question", "received on", room.name, message);
                    this.doNewQuestion(message.newQ);
                });
                room.onMessage("change_game", (message) => {
                    console.log("change_game", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("do_results", (message) => {
                    console.log("do_results", "received on", room.name, message);
                    this.setState({ answer: null, bonusAnswer: null, });
                    this.setView(ViewTypes.Default);
                });
                //room.onMessage("force_answer", (message) => {
                //    console.log("force_answer", "received on", room.name, message);
                //    this.doForceAnswer();
                //});
                room.onMessage("host_joined_lobby", (message) => {
                    console.log("host_joined_lobby", "received on", room.name, message);
                    this.goToLobby();
                });
                room.onMessage("game_starting", (message) => {
                    console.log("game_starting", "received on", room.name, message);
                    if (message.gameId != gameId) {
                        this.goToLobby();
                    }
                });
                room.onMessage("game_over", (message) => {
                    console.log("game_over", "received on", room.name, message);
                    let title, content, winner = false;
                    for (let i = 0; i < message.winners.length; i++) {
                        if (message.winners[i].id == this.state.myId) {
                            winner = true;
                        }
                    }
                    if (winner) {
                        title = "YOU WIN!";
                        content = "Nice one!";
                    } else {
                        title = "YOU LOSE!";
                        content = "Unlucky...";
                    }
                    this.setState({ title, content, });
                    setTimeout(() => {
                        this.setState({ title: "Waiting for host to start another game", content: "" });
                    }, 10000);
                });
                room.onMessage("update_content", (message) => {
                    console.log("update_content", "received on", room.name, message);
                    this.updateContent(message.title, message.content, true);
                });

                room.onMessage("show_tutorial", (message) => {
                    console.log("show_tutorial", "received on", room.name, message);
                    this.setView(ViewTypes.Tutorial);
                });
                room.onMessage("begin_tutorial", (message) => {
                    console.log("begin_tutorial", "received on", room.name, message);
                });
                room.onMessage("toggle_pause", (message) => {
                    console.log("toggle_pause", "received on", room.name, message);
                    this.setState({ isPaused: message.pause });
                });

                room.onMessage("location_confirmed", (message) => {
                    console.log("location_confirmed", "received on", room.name, message);
                    this.setState({ gotLocationPing: true, });
                });

                room.onError((code, message) => {
                    console.log(this.client.id, "couldn't join", room.name);
                    LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Can of Squirms, code: ${code} Message: ${JSON.stringify(message)}`);
                    //LoggingService.logError(message, code);
                });
                room.onLeave((code) => {
                    console.log(this.client.id, "left", room.name);
                    this.setState({ connected: false, });
                    LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Can of Squirms, Code: ${code}`);
                    if (!this.state.redirectURL) {
                        if (code == 4050) {
                            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                        } else {
                            this.doReconnect();
                        }
                    } else {
                        setTimeout(() => {
                            this.setState({ redirect: true, });
                        }, 1500);
                    }
                });
            }).catch(e => {
                console.log("JOIN ERROR", e);
                this.setState({ blockNav: false, });
                this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });

                const message = e.message ? e.message : "An error occured joining Squirms.";
                if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Can of Squirms: ${JSON.stringify(e)}`);
                //LoggingService.logError(message, e);

                if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
            });
        }
    }


    render() {
        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        {/*<Lottie options={bgAnim} width="100%" height="100%" isClickToPauseDisabled={true} style={{ left: 0, position: 'absolute', }} />*/}
                        <img src={bgAnimGif} className={styles.background} />
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>
                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                <div id="clientContainer" className={styles.clientContainer}>
                    {/*<Lottie options={bgAnim} width="100%" height="100%" isClickToPauseDisabled={true} style={{ position: 'absolute', }} />*/}
                    <img src={bgAnimGif} className={styles.background} />
                    {
                        this.state.room ?
                            this.state.connected ?
                                <React.Fragment>
                                    {
                                        this.state.connectionIssue &&
                                        <div className={styles.connectionIssueContainer}>
                                            <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                            <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                        </div>
                                    }
                                    {
                                        this.state.isPaused &&
                                        <div className={styles.pauseContainer}>
                                            <div className={styles.pauseText}>Paused</div>
                                        </div>
                                    }
                                    {
                                        this.state.showDefaultView &&
                                        <ClientContent room={this.state.room} player={this.state.player} title={this.state.title} content={this.state.content} hostConnected={this.state.hostConnected} players={this.state.players} />
                                    }
                                    {
                                        this.state.showTutorialView &&
                                        <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                            <div className={styles.skipButton}>Skip Tutorial</div>
                                        </div>
                                    }
                                    {
                                        this.state.showQuestionView && this.state.vsPlayers.length > 0 &&
                                        <React.Fragment>
                                            {/*<div className={styles.roundTimer}>{this.state.roundTimer}</div>*/}
                                            <div id="headerSection" className={styles.headerSection}>
                                                <div id="questionText" className={styles.questionText}>{this.state.question.question}</div>
                                            </div>
                                            {
                                                this.state.isVSPlayer ?
                                                    <div id="vsContainer" className={styles.buttonContainer}>
                                                        <div id="buttonSection" className={styles.buttonSection}>
                                                            <button id="p1Button"
                                                                className={`${styles.answerButton} ${this.state.answer == this.state.vsPlayers[0].id ? styles.selected : ""}`}
                                                                onClick={() => this.choosePlayer(this.state.vsPlayers[0].id)}>
                                                                {this.state.vsPlayers[0].name}
                                                            </button>
                                                            <button id="p2Button"
                                                                className={`${styles.answerButton} ${this.state.answer == this.state.vsPlayers[1].id ? styles.selected : ""}`}
                                                                onClick={() => this.choosePlayer(this.state.vsPlayers[1].id)}>
                                                                {this.state.vsPlayers[1].name}
                                                            </button>
                                                        </div>
                                                        <input autoComplete="off" type="text" maxLength={40} id="descBox" className={styles.descBox} onChange={this.updateDesc} name="descBox" placeholder="Write the reason!" />
                                                        <button id="submitButton" className={styles.submitButton} value="Submit" onClick={() => this.submitVSAnswer()}>Submit</button>
                                                    </div>
                                                    :
                                                    <div id="guessContainer" className={styles.buttonContainer}>
                                                        <div id="hintText" className={styles.smallerText}>{this.state.hintText}</div>
                                                        <div id="buttonSection" className={styles.buttonSection}>
                                                            <button id="agreeButton" className={`${styles.answerButton} ${this.state.answer == this.state.vsPlayers[0].id ? styles.selected : ""}`} onClick={() => this.chooseOptionAndSubmit(this.state.vsPlayers[0].id)}>{this.state.vsPlayers[0].name}</button>
                                                            <button id="disagreeButton" className={`${styles.answerButton} ${this.state.answer == this.state.vsPlayers[1].id ? styles.selected : ""}`} onClick={() => this.chooseOptionAndSubmit(this.state.vsPlayers[1].id)}>{this.state.vsPlayers[1].name}</button>
                                                            <button id="agreeButton" className={`${styles.answerButton} ${styles.smaller} ${this.state.answer == "disagree" ? styles.selected : ""}`} onClick={() => this.chooseOptionAndSubmit("disagree")}>THEY WON'T AGREE</button>
                                                        </div>
                                                    </div>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <Loading loadingText={"Trying to reconnect you..."} noBg={true} hideLoader={false} />
                            :
                            <Loading loadingText={"Connecting you to the game..."} noBg={false} hideLoader={false} />
                    }
                </div>
            </div>
        );
    }
}
